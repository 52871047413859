.error {
    color: red;
    font-size: 12px;
}

.md-input-filed {
    margin-bottom: 0px;
}

.md-error-filed {
    margin-left:36px;
    margin-top: 0px;
    padding-top: 0px;
}

.logo-img {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    border-radius: 10px;
    margin-bottom: 4px;
}

.dailyRewardOverFlow::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #737272;
}

.dailyRewardOverFlow::-webkit-scrollbar {
    width: 10px;
}

.dailyRewardOverFlow::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #65D6AD;
    border: 1px solid #000;
}
.list {
  align-content: space-evenly;
  position: relative;
  justify-content: center;
  align-items: center;
}

.list:hover .icon {
  position: relative;
  font-size: 1.5em;
  text-align: center;
  transform: translateY(-47px);
  transition: 0.5s;
  align-items: center;
  justify-content: center;
}

.topNavButton {
  text-transform: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.list:hover .topNavButton {
  opacity: 1;
  transform: translateY(-15px);
  color: white;
  transition: 0.5s;
  background: #29fd53;
  border-radius: 50%;
  border: 6px solid var(--clr);
  transition: 0.5s;
}

.companyLogo:hover {
  cursor: pointer;
}

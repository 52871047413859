body {
  margin: 0;
  padding: 0 !important;
  font-family: sans-serif;
}

.MuiTooltip-popper{
	z-index: 99999 !important;
}

::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 7px;
	height: 7px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #C0C0C0;
}
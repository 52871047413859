.mainDiv {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('https://taco-webapp.s3-us-west-2.amazonaws.com/email/logo.png'); */
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: 1000px 400px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 99999;
}

.square {
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: 120px;
  animation: rotate 4s linear infinite;
  border: 10px dashed rgba(153, 51, 0, 0.1);
  box-shadow: -2px 2px 58px -12px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px 2px 58px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 2px 58px -12px rgba(0, 0, 0, 0.75);
  /* box-shadow: 0 0 0 5px rgba(153, 51, 0, 0.6),
    inset 0 0 0 5px rgba(153, 51, 0, 0.5); */
  background: linear-gradient(45deg, #ffac33, #b26a00);
}
.square span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.square span:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  border-top: 10px solid #fff;
  animation: animate 2s linear infinite;
}
.square span:nth-child(1) {
  transform: rotate(0deg);
}
.square span:nth-child(2) {
  transform: rotate(90deg);
}
.square span:nth-child(3) {
  transform: rotate(180deg);
}
.square span:nth-child(4) {
  transform: rotate(270deg);
}

.square span:nth-child(1):before {
  animation-delay: 0s;
}
.square span:nth-child(2):before {
  animation-delay: 0.5s;
}
.square span:nth-child(3):before {
  animation-delay: 1s;
}
.square span:nth-child(4):before {
  animation-delay: 1.5s;
}

@keyframes animate {
  0% {
    left: -100%;
  }
  25% {
    left: 0;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.loadingText {
  position: relative;
  top: 7em;
  right: 8em;
  align-items: center;
  justify-content: center;
}
